.emerald-signup-page {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 102px);
  background-image: url(../../assets/images/emerald-signup-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
}

.emerald-signup__header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.emerald-signup__header_text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 25px;
  width: 100%;
}

.emerald-signup__content {
  width: 100%;
  max-width: 660px;
}

.emerald-signup__content_title {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.24px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.emerald-signup__content_subtitle {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 30px;
}

.emerald-signup__content_benefits {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
}

.emerald-benefit-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.emerald-signup__content_benefit {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  width: 260px;
  flex: 0 0 auto;
  color: #1c1c1e;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.emerald-signup__content_splitter {
  width: 260px;
  height: 1px;
  background-color: #a6a6a6;
  margin: 20px 0;
}

.emerald-price {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 8px;
  display: none;
}

.emerald-price__value {
  color: #000;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.emerald-price__symbol {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
  top: -5px;
}

.emerald-signup__footer {
  width: 300px;
  position: relative;
}

.emerald-get-access-button {
  border-radius: 5px !important;
  background: linear-gradient(269deg, #55efc4 0%, #000 100%) !important;
  padding: 16px !important;
  color: #fff !important;
  font-family: Inter !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  width: 100% !important;
  box-shadow: none !important;
  z-index: 2 !important;
  text-transform: initial !important;
}

.emerald-get-access-button__shadow {
  border-radius: 8px;
  opacity: 0.4;
  background: linear-gradient(269deg, #55efc4 0%, #000 100%);
  filter: blur(10px);
  height: 30px;
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 100%;
  z-index: 1;
}

body.dark {
  .gain-access-text {
    color: #fff;
  }
  .gain-access-list{
    color: #fff;
  }
  .emerald-signup-page {
    background-image: url(../../assets/images/dark-emerald-signup-bg.png);
  }

  .emerald-signup__content_title {
    color: #fff;
  }

  .emerald-signup__content_benefit {
    color: #e4e5e8;
  }

  .emerald-price__value {
    color: #fff;
  }

  .emerald-price__symbol {
    color: #fff;
  }

  .emerald-get-access-button {
    background: linear-gradient(269deg, #44c4a1 0%, #349a7d 100.01%) !important;
    color: #fff !important;
  }

  .emerald-signup__content_subtitle {
    color: #e4e5e8;
  }

  .emerald-get-access-button__shadow {
    background: linear-gradient(269deg, #44c4a1 0%, #349a7d 100%);
  }
}

@media (max-width: 1134px) {
  .emerald-signup__header {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .emerald-signup__header_text {
    align-items: center;
  }
}

@media (max-width: 767px) {
  .emerald-signup__header_text {
    gap: 15px;

    svg {
      width: 100%;
    }
  }

  .emerald-benefit-row {
    flex-direction: column;
  }

  .emerald-signup__content_benefits {
    align-items: center;
  }

  .emerald-signup__content_splitter {
    width: 100%;
  }

  .emerald-signup__content_title {
    text-align: center;
  }

  .emerald-signup__content_subtitle {
    text-align: center;
  }

  .emerald-price {
    justify-content: center;
  }

  .emerald-signup__footer {
    margin: 0% auto;
    width: 100%;
  }

  .emerald-signup-page {
    padding: 30px;
  }
}

@media (max-width: 575px) {
  .emerald-signup-page {
    gap: 20px;
  }

  .emerald-signup__content_title {
    font-size: 20px;
  }

  .emerald-signup__content_subtitle {
    margin-bottom: 20px;
    font-size: 14px;
  }

  .emerald-signup__header_text {
    gap: 5px;
  }
}

.gain-access-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px;
  border-radius: 10px;  
  gap: 2px;
  width: 370px;
  border: 1px solid #00C2A9;
}

.gain-access-text {
  padding-left: 2px;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: 0.15px;
}

.gain-access-list {
  color: rgba(0, 0, 0, 0.6);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%; /* 20.02px */
  letter-spacing: 0.17px;
}

.gain-access-list__items {
  list-style-type: disc;
  margin-left: 20px;
}