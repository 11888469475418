.plaid-integration-alert {
  display: flex;
  padding: 14px 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  gap: 12px;

  &.primary-alert {
    background-color: #E5F6FD;
    color: #014361;

    .plaid-integration-alert-icon {
      color: #0288D1;
    }

    .plaid-integration-alert-button {
      background: #0288D1 !important;
    }
  }

  &.success-alert {
    background-color: #EDF7ED;
    color: #1E4620;

    .plaid-integration-alert-icon {
      color: #2E7D32;
    }

    .plaid-integration-alert-button {
      background: #2E7D32 !important;
    }
  }

  &.error-alert {
    background-color: #FDEDED;
    color: #5F2120;

    .plaid-integration-alert-icon {
      color: #D32F2F;
    }

    .plaid-integration-alert-button {
      background: #D32F2F !important;
    }
  }
}

.plaid-integration-alert-body {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  flex: 1 0 0;
}

.plaid-integration-alert-content {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  gap: 4px;
}

.plaid-integration-alert-title {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.plaid-integration-alert-subtitle {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.plaid-integration-alert-button {
  padding: 4px 10px !important;
  border-radius: 4px !important;
  border: none !important;
  color: #FFF !important;
  font-family: Inter !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  letter-spacing: 0.46px !important;
  text-transform: initial !important;
}

body.dark {
  .plaid-integration-alert {
    &.primary-alert {
      background-color: #071318;
      color: #B8E7FB;

      .plaid-integration-alert-icon {
        color: #29B6F6;
      }

      .plaid-integration-alert-button {
        background: #29B6F6 !important;
      }
    }

    &.success-alert {
      background-color: #0C130D;
      color: #CCE8CD;

      .plaid-integration-alert-icon {
        color: #66BB6A;
      }

      .plaid-integration-alert-button {
        background: #66BB6A !important;
      }
    }

    &.error-alert {
      background-color: #160B0B;
      color: #F4C7C7;

      .plaid-integration-alert-icon {
        color: #F44336;
      }

      .plaid-integration-alert-button {
        background: #F44336 !important;
      }
    }
  }
}

@media (max-width: 575px) {
  .plaid-integration-alert {
    flex-direction: column;
  }
}