.home-title {
  position: relative;
  width: auto;
  height: 29px;
  white-space: nowrap;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  color: #000000;
}

.home-font-family {
  font-family: "Inter", sans-serif;
}

.tableCell {
  padding: 2px !important;
}

.content_div {
  height: calc(100vh - 97px);
  overflow: hidden;
  overflow-y: scroll;

  padding-bottom: 0;
  text-align: center;
  z-index: 1;
  padding-right: 20px;
}

.card {
  border: none;
  border-radius: 16px;
}

@media (max-width: 991px) {
  .content_div {
    padding-left: 20px;
  }
}

.search-icon {
  position: absolute;
  top: 13px;
  right: 26px;
  color: #b7b4c7;
  font-size: 20px;
}

.search-wrapper .ant-select-selection-search-input {
  font-size: 15px;
  padding: 0 69px 0 27px;
  height: 47px !important;
  border-radius: 30px;
  width: 100% !important;
  border: none;
}

.contact .search-wrapper .ant-select-selection-search-input,
.about .search-wrapper .ant-select-selection-search-input,
.privacy .search-wrapper .ant-select-selection-search-input,
.disclaimer .search-wrapper .ant-select-selection-search-input,
.terms .search-wrapper .ant-select-selection-search-input,
.safe-sender .search-wrapper .ant-select-selection-search-input {
  /*background-color: #F8FAFC;*/
  background-color: #ffffff;
}

.search-wrapper .ant-select-selection-search-input::placeholder {
  color: rgba(0, 0, 0, 0.54) !important;
}

.search-wrapper .ant-select-selection-search-input:focus {
  outline: none;
}

.search-wrapper .ant-select-selection-search-input::-webkit-input-placeholder {
  color: peachpuff;
  font-size: 15px;
}

.search-wrapper .ant-select-selection-search-input::-moz-placeholder {
  color: peachpuff;
  font-size: 15px;
}

.search-wrapper .ant-select-selection-search-input:-ms-input-placeholder {
  color: peachpuff;
  font-size: 15px;
}

.search-wrapper .ant-select-selection-search-input::placeholder {
  color: peachpuff;
  font-size: 15px;
}

.topnav .search-container button:hover {
  background: #ccc;
}

.home-page-header {
  height: 20px;
}
.tabs--bordered-bottom::-webkit-scrollbar {
  display: none;
}
.MuiDataGrid-window::-webkit-scrollbar {
  display: none;
}

.content-in-layout {
  margin-left: 0;
  min-height: 100vh;
  overflow-x: hidden;
}

/* .wealth-series-atlas .content-in-layout, .wealth-series-titan .content-in-layout, .wealth-series-everest .content-in-layout,
.release-notes .content-in-layout {
  overflow-x: initial;
  margin: 0 auto !important;
  max-width: 1400px;
  padding: 0 25px !important;
} */

.wealth-series-lite .content-in-layout {
  overflow-x: initial;
  margin: 0 auto !important;
  max-width: 1400px;
  padding: 0 !important;
}

.homepage-main-content {
  margin: 0 16px 0 16px;
}

@media (min-width: 992px) {
  .home-page-header {
    height: 97px;
  }
}

.ant-menu-vertical .ant-menu-item:not(:last-child) {
  margin-bottom: 21.7px;
}

/* Mobile header */

.search-transition-enter {
  opacity: 0;
}

.search-transition-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.search-transition-exit {
  opacity: 1;
}

.search-transition-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

/* New styles for Home Header */
.home-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 48px 12px 28px;
}

.wealth-series-atlas .home-header {
  background: url(../../assets/images/wealth-series/overview/atlas-bg.png) no-repeat;
  background-size: 100% 100%;
}

.wealth-series-titan .home-header {
  background: url(../../assets/images/wealth-series/overview/titan-bg.png) no-repeat;
  background-size: 100% 100%;
}

.wealth-series-everest .home-header {
  background: url(../../assets/images/wealth-series/overview/everest-bg.png) no-repeat;
  background-size: 100% 100%;
}

body.dark.wealth-series-atlas .home-header, body.dark.wealth-series-titan .home-header, body.dark.wealth-series-everest .home-header, body.dark.wealth-series-lite-signup .home-header {
  background-color: #3C3C3F;
}

body.dark.wealth-series-lite .home-header {
  background-color: #505154;
}

body.dark.release-notes .home-header {
  background-color: #3C3C3F;
}

.home-header-notification-icon {
  color: #cccccc;
  cursor: pointer;
}

.home-header-right-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  cursor: pointer;
}

.layout-toggle-element {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.layout-toggle-element-label {
  color: rgba(0, 0, 0, 0.87);
  text-align: right;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.home-header-right-box-spliter {
  width: 1px;
  height: 36px;
  background: #cccccc;
}

.home-header-right-box-user {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 5px 10px;
}

.home-header-user-avatar {
  width: 34px;
  height: 34px;
  border-radius: 50%;
}

.header-profile-user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
}

.header-profile-user-name {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.011px;
}

.header-profile-user-type {
  color: #979797;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.011px;
}

body.dark .header-profile-user-name {
  color: #ffffff;
}

body.dark .header-profile-user-type {
  color: #e4e5e8;
}

.home-header-user-menu ul {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 6px;
}

.home-header-user-menu-item {
  padding: 10px 40px 10px 20px !important;
  color: #000 !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  letter-spacing: -0.011px !important;
  width: 100% !important;
}

.home-header-user-menu-item:hover {
  background-color: rgba(0, 123, 255, 0.2) !important;
}

.home-header-user-menu-item.active {
  background-color: #007bff !important;
  color: #ffffff !important;
}

body.dark .home-header-user-menu-item {
  color: #ffffff !important;
}

body.dark .home-header-user-menu-item:hover {
  background-color: rgba(94, 238, 205, 0.2) !important;
}

body.dark .home-header-user-menu-item.active {
  background-color: #5eeecd !important;
  color: #000 !important;
}

.header-search .MuiAutocomplete-popper {
  transform: translate(15px, 40px) !important;
}

.is-mobile-search .header-search .MuiAutocomplete-popper {
  transform: translate(15px, 40px) !important;
  width: 380px !important;
}

.mobile-header-title-search-bar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.is-mobile-search {
  justify-content: space-between;
}

.dashboard-table-container-title {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 15px;
}

body.dark .dashboard-table-container-title {
  color: #ffffff;
}

.base-Select-popup {
  z-index: 10000;
}

@media (max-width: 1119px) {
  .layout-toggle-element {
    flex-direction: column;
    gap: 0;
  }

  .layout-toggle-element-label {
    text-align: center;
  }
}

body.dark .layout-toggle-element-label {
  color: #ffffff;
}

@media (max-width: 450px) {
  .header-profile-user-name {
    display: none;
  }

  .header-profile-user-type {
    display: none;
  }
}