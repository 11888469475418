.simple-submenu-items {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
}

.simple-submenu-item {
  padding: 12px 24px;
  color: var(--submenu-text);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: var(--Inter, Inter);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.15px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: var(--submenu-hover);
  }

  &.active {
    background-color: var(--menu-item-active);
    font-weight: 600;
  }
} 