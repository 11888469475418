// Add dark theme variables
:root {
  --sidebar-bg: #fff;
  --sidebar-border: #e0e0e0;
  --menu-item-hover: #f5f5f7;
  --menu-item-active: #f0f0f5;
  --menu-text: #000;
  --menu-icon: rgba(0, 0, 0, 0.56);
  --divider-color: rgba(0, 0, 0, 0.12);
  --submenu-bg: white;
  --submenu-title: rgba(0, 0, 0, 0.60);
  --submenu-text: #333;
  --submenu-hover: #f5f5f7;
}

body.dark {
  --sidebar-bg: #1d1d1d;
  --sidebar-border: #2d2d2d;
  --menu-item-hover: #2b2b2b;
  --menu-item-active: #0f2740;
  --menu-text: #fff;
  --menu-icon: rgba(255, 255, 255, 0.8);
  --divider-color: #2d2d2d;
  --submenu-bg: #1d1d1d;
  --submenu-title: rgba(255, 255, 255, 0.6);
  --submenu-text: #fff;
  --submenu-hover: #2b2b2b;
}

.sidebar {
  width: 260px;
  height: 100vh;
  background-color: var(--sidebar-bg);
  border-right: 1px solid var(--sidebar-border);
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease;
  overflow: hidden;
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  .pl-5{
    padding-left: 5px;
  }
  .ml-8{
    margin-left: 8px;
  }
  .mobile-collapse-btn {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--menu-text);
    padding: 4px;
    .collapse-icon-mobile {
      width: 45px !important;
    }
  }
  &.collapsed {
    width: 70px;
  }

  // Mobile sidebar
  &.mobile {
    width: 100%;
    left: -100%;
    transition: left 0.3s ease;

    &.mobile-open {
      left: 0;
    }

    .sidebar-menu {
      padding-top: 0;
    }

    .mobile-sidebar-header {
      display: flex;
      padding: 16px;
      padding-left: 13px;

    }
   
    // Mobile submenu
    .mobile-submenu {
      height: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: 5px;
      .mobile-submenu-header {
        display: flex;
        flex-direction: column;
        padding: 16px;

        .back-button {
          display: flex;
          align-items: center;
          gap: 8px;
          background: none;
          border: none;
          cursor: pointer;
          padding: 0;
          color: var(--menu-text);
          font-size: 14px;
          font-weight: 500;
          align-self: flex-start;

          &:hover {
            color: var(--menu-text);
            opacity: 0.8;
          }
        }
      }

      .mobile-submenu-content {
        width: 100% ;
        flex: 1;
        overflow-y: auto;
        padding: 8px 0;
      }
    }

    .sidebar-footer.mobile {
      border-top: 1px solid var(--sidebar-border);
      padding: 20px;
    }
  }

  .sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid var(--sidebar-border);

    .collapse-icon-mobile {
      width: 30px;
      height: 30px;
    }

    .collapse-btn.MuiIconButton-root {
      padding: 0 !important;
    }

    .logo {
      display: flex;
      align-items: center;
      gap: 5px;

      .ta-logo-sidebar {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }

      .logo-text {
        font-weight: 700;
        font-size: 20px;
        white-space: nowrap;
        letter-spacing: -0.5px;
        color: var(--menu-text);
      }
    }

    .collapse-icon {
      width: 45px;
      height: 45px;
    }


    .collapse-btn {
      background: none;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--menu-text);
      transition: color 0.2s;

      &:hover {
        color: var(--menu-text);
        opacity: 0.8;
      }
    }
  }

  .sidebar-menu {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .menu-section {
      &.top {
        flex-shrink: 0;
      }

      &.middle {
        flex-shrink: 0;
      }

      &.bottom {
        margin-top: auto;
        flex-shrink: 0;
      }
    }

    .divider {
      height: 1px;
      background-color: var(--divider-color);
      flex-shrink: 0;
    }

    .menu-item {
      position: relative;
      cursor: pointer;
      color: var(--menu-text);

      &:hover {
        background-color: var(--menu-item-hover);
      }

      &.active {
        background-color: var(--menu-item-active);
      }

      .menu-item-content {
        min-width: 100%;
        display: flex;
        align-items: center;
        padding: 12px 20px;
        gap: 12px;

        .icon {
          min-width: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--menu-icon);
        }

        .gold-icon {
          color: #ffc107;
        }

        .label {
          flex: 1;
          white-space: nowrap;
          color: var(--menu-text);
        }

        .new-badge {
          background-color: #007bff;
          color: white;
          font-size: 12px;
          padding: 2px 8px;
          border-radius: 12px;
          white-space: nowrap;
        }

        .submenu-arrow {
          width: 16px !important;
          height: 16px !important;
          color: var(--menu-icon) !important;
        }
      }

      // Styles for disabled menu items
      &.disabled {
        cursor: not-allowed;
        opacity: 0.5; // Grey out the item
        pointer-events: none; // Prevent mouse interactions

        // Ensure active styles don't override disabled look
        &.active {
          background-color: transparent; // Remove active background
        }

        .menu-item-content .icon,
        .menu-item-content .label {
          color: var(--menu-icon); // Use the less prominent icon color for text too
        }

        // Hide submenu arrow if item is disabled
        .submenu-arrow-wrapper {
          display: none;
        }
      }
    }
  }

  .sidebar-footer {
    padding: 15px 20px;
    border-top: 1px solid var(--sidebar-border);
    display: flex;
    justify-content: center;

    .theme-toggle-container {
      width: 100%;
    }
  }
}

/* Full-height submenu styles */
.fullheight-submenu {
  position: fixed;
  top: 0;
  left: 260px;
  width: 280px;
  height: 100vh;
  background-color: var(--submenu-bg);
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s ease, visibility 0.15s ease;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -20px;
    width: 20px;
    height: 100%;
    background: transparent;
    z-index: 2;
  }
  
  // Add a bridge element that extends from the menu to submenu
  // This prevents any gaps where the mouse can "fall through"
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -260px; // Extend all the way back to the sidebar
    width: 240px; // Bridge width (260px - 20px from ::before)
    height: 100%;
    background: transparent;
    pointer-events: none; // Allow clicking through to the menu
    z-index: -1;
  }

  .submenu-content {
    height: 100%;
    display: flex;
    flex-direction: column;

    &:has(.simple-submenu-items) {
      padding: 12px 0;

      .simple-submenu-items {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
    }

    &:has(.submenu-items) {
      padding: 20px;
    }
  }

  .submenu-title {
    color: var(--submenu-title);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: var(--Inter, Inter);
    font-size: var(--fontSize-semantic-overline, 12px);
    font-style: normal;
    font-weight: var(--Regular, 400);
    line-height: 266%;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-left: 12px;
  }

  .submenu-items {
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow-y: auto;
  }
}

/* Adjust for collapsed sidebar */
.sidebar.collapsed+#submenu-container .fullheight-submenu {
  left: 70px;

  &::before {
    left: -20px;
    width: 20px;
  }
  
  // Extend the bridge element in collapsed mode
  &::after {
    left: -70px; // From the start of collapsed sidebar
    width: 50px; // Bridge width (70px - 20px from ::before)
  }
}

#submenu-container {
  pointer-events: none;
}

#submenu-container .fullheight-submenu {
  pointer-events: auto;
}

.simple-submenu-items {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &.mobile {
    gap: 0;

    .simple-submenu-item {
      padding: 16px 20px;
      border-bottom: 1px solid var(--sidebar-border);

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .simple-submenu-item {
    padding: 8px 20px;
    cursor: pointer;
    color: var(--submenu-text);
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    gap: 13px;

    &:hover {
      background-color: var(--submenu-hover);
    }

    .new-badge {
      background-color: #007bff;
      color: white;
      font-size: 12px;
      padding: 2px 8px;
      border-radius: 12px;
      white-space: nowrap;
    }
  }
}

// Mobile header menu button
.mobile-menu-toggle {
  background: none;
  border: none;
  padding: 8px;
  color: var(--menu-text);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: var(--menu-text);
    opacity: 0.8;
  }
}