.plaid-integration-dialog {
  .MuiDialog-paper {
    min-width: 400px;
    max-width: 450px;
    width: 90%;
    border-radius: 8px;
  }

  .MuiDialogContent-root {
    padding: 12px 24px 16px !important;
  }

  .MuiDialogActions-root {
    padding: 0px 24px 24px !important;
    justify-content: center !important;
  }

  .plaid-integration-modal-dialog-title {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    padding: 20px 24px 4px !important;

    &.justify-content-center {
      justify-content: center;
      padding-top: 24px !important;
      
      svg {
        font-size: 40px;
      }
    }
  }

  .plaid-integration-modal-dialog-subtitle {
    color: rgba(0, 0, 0, 0.87);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: 0.15px;
    width: calc(100% - 40px);
  }

  .plaid-integration-modal-dialog-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    padding: 4px 0;
  }

  .plaid-integration-prices {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
  }

  .plaid-integration-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: rgba(0, 0, 0, 0.87);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.15px;
  }

  .plaid-integration-price-title {
    font-weight: 600;
  }

  .plaid-integration-price-value {
    font-weight: 400;
  }

  .plaid-integration-divider {
    width: 100%;
    height: 1px;
    background: #0000001F;
    margin: 4px 0;
  }

  .plaid-integration-balance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: rgba(0, 0, 0, 0.87);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.15px;
    font-weight: 400;
    margin-top: 4px;
  }

  .plaid-integration-balance-title {
    color: rgba(0, 0, 0, 0.60);
  }

  .plaid-integration-modal-action-button {
    border-radius: 4px !important;
    background: #007BFF !important;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20) !important;
    color: #FFF !important;
    font-family: Inter !important;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 26px !important;
    letter-spacing: 0.46px !important;
    text-transform: initial !important;
    min-width: 160px !important;
    padding: 6px 20px !important;
  }

  .plaid-integration-blue {
    color: #0288D1;
  }

  .plaid-integration-red {
    color: #D32F2F;
  }

  .plaid-integration-green {
    color: #2E7D32;
  }

  .plaid-integration-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    padding: 8px 0;
  }

  .plaid-integration-status-title {
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: 0.15px;
  }

  .plaid-integration-status-detail {
    color: rgba(0, 0, 0, 0.60);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.15px;
  }
}

body.dark {
  .plaid-integration-dialog {
    .plaid-integration-modal-dialog-title {
      button {
        color: #FFFFFF8F !important;
      }
    }

    .plaid-integration-modal-dialog-subtitle {
      color: rgb(255, 255, 255);
    }

    .plaid-integration-price {
      color: rgb(255, 255, 255);
    }

    .plaid-integration-balance {
      color: rgb(255, 255, 255);
    }

    .plaid-integration-balance-title {
      color: rgba(255, 255, 255, 0.70);
    }

    .plaid-integration-modal-action-button {
      background: #009EFF !important;
    }

    .plaid-integration-blue {
      color: #29B6F6;
    }

    .plaid-integration-red {
      color: #F44336;
    }

    .plaid-integration-green {
      color: #66BB6A;
    }

    .plaid-integration-status-title {
      color: rgb(255, 255, 255);
    }

    .plaid-integration-status-detail {
      color: rgba(255, 255, 255, 0.70);
    }
  }
}

@media (max-width: 600px) {
  .plaid-integration-dialog {
    .MuiDialog-paper {
      min-width: 280px;
      width: 95%;
      margin: 16px;
    }
    
    .plaid-integration-modal-dialog-title {
      padding: 16px 20px 4px !important;
      
      &.justify-content-center {
        padding-top: 20px !important;
        
        svg {
          font-size: 32px;
        }
      }
    }
    
    .plaid-integration-modal-dialog-subtitle {
      font-size: 16px;
    }
    
    .MuiDialogContent-root {
      padding: 8px 20px 12px !important;
    }
    
    .MuiDialogActions-root {
      padding: 12px 20px 20px !important;
    }
    
    .plaid-integration-status-title {
      font-size: 18px;
    }
    
    .plaid-integration-status-detail {
      font-size: 14px;
    }
  }
}