.global-alerts-container {
  position: relative;
  width: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 16px;
  margin-bottom: 16px;
}
.wealth-series-lite-signup {
  .global-alerts-container {
    margin-bottom: 0;
    padding: 0 0 !important;   
  }
}
.global-alert-wrapper {
  position: relative;
  animation: fadeIn 0.3s ease-out forwards;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.global-alert-close {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  transition: background-color 0.2s;
  z-index: 10;
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
}

body.dark {
  .global-alert-close {
    color: rgba(255, 255, 255, 0.7);
    background-color: rgba(0, 0, 0, 0.3);
    
    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} 