.ant-menu-item-selected {
  background-color: transparent !important;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background-color: #005dc3;
}

.ant-menu-vertical > .ant-menu-item {
  height: 50px;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.ant-menu-vertical > .ant-menu-item.ant-menu-item-active {
  background-color: #005dc3;
}

.sidebar-link__title {
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
}
.sidebar-link {
  width: 100%;
  height: 100%;
}

.ant-menu-inline-collapsed > li > span {
  font-size: 0;
}

.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner {
  padding: 11px 20px;
  line-height: 1;
  border-radius: 10px;
  font-size: 16px;
}

.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner,
.ant-menu-inline-collapsed-tooltip .ant-tooltip-arrow-content {
  background-color: #9ea7b3;
}

/* Layout structure */
.layout-wrapper {
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  width: 100%;
}

#layout-wrapper {
  margin-left: 260px;
  width: calc(100% - 260px);
  transition: margin-left 0.3s ease, width 0.3s ease;
  display: flex;
  flex-direction: column;
}

.sidebar.collapsed + #submenu-container + #layout-wrapper {
  margin-left: 70px;
  width: calc(100% - 70px);
}

#submenu-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999;
}

#submenu-container .submenu {
  pointer-events: auto;
}

.main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.sidebar-layout-content {
  flex: 1;
  padding: 20px;
}

.sidebar-content-container {
  min-width: 100%;
  margin: 0 auto;
}

@media (max-width: 991px) {
  #layout-wrapper {
    margin-left: 0;
    width: 100%;
  }
}
