@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0.1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes gradientAura {
  0% {
    background: linear-gradient(150deg, #FFAB8A, #FFAB8A);
    opacity: 0;
  }
  100% {
    background: linear-gradient(150deg, #FFAB8A, #94C8FF);
    opacity: 1;
  }
}

@keyframes continuousAura {
  0%, 100% {
    background: linear-gradient(150deg, #FFAB8A, #94C8FF);
    opacity: 1;
  }
  50% {
    background: linear-gradient(150deg, #94C8FF, #FFAB8A);
    opacity: 0.8;
  }
}

.ws-lite-signup {
  padding-top: 120px;
  padding-left: 60px;
  padding-right: 60px;
  min-height: calc(100vh - 96px);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.ws-lite-signup__header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
}

.ws-lite-signup__header__left {
  flex: 1 1 auto;
}

.ws-lite-signup__logo {
  width: 180px;
}

.ws-lite-signup__header__title {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 115%;
  letter-spacing: -0.5px;
  margin: 20px 0;
}

.ws-lite-signup__header__subtitle {
  color: rgba(0, 0, 0, 0.60);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin-bottom: 30px;
  width: 520px;
}

.ws-lite-signup__header__button {
  padding: 7px 24px !important;
  border-radius: 8px !important;
  border: 1px solid #007BFF !important;
  background: #007BFF !important;
  color: #FFF !important;
  font-family: Inter !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 26px !important;
  text-transform: initial !important;
}

.small-letters-em {
  font-size: 10px;
  position: relative;
  top: -4px;
}

.ws-lite-signup__header__right {
  width: 550px;
  flex: 0 0 auto;
  position: absolute;
  right: 0;
  animation: slideInFromRight 400ms ease-in-out;
}

.ws-lite-signup__gradient-dot {
  position: absolute;
  width: 400px;
  height: 300px;
  background: linear-gradient(150deg, #FFAB8A 1.52%, #94C8FF 63.11%);
  filter: blur(40px);
  left: -70px;
  top: -30px;
  z-index: 2;
  border-radius: 50%;
  animation: continuousAura 3s ease-in-out infinite;
}

.ws-lite-signup__frame {
  width: 100%;
  position: relative;
  z-index: 3;
}

.ws-lite-signup__content {
  margin-top: 150px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.ws-lite-signup__item {
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
}

.ws-lite-signup__item__img {
  width: 40px;
}

.ws-lite-signup__item_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.ws-lite-signup__item__title {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.ws-lite-signup__item__subtitle {
  color: rgba(0, 0, 0, 0.60);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.ws-lite-signup__footer {
  position: relative;
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.60);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
  text-align: center;
  width: 100%;
}

.wealth-series-lite-signup {
  .content-in-layout {
    margin-right: 0 !important;
  }

  .sidebar-content-container {
    max-width: initial !important;
  }
}

body.dark {
  .ws-lite-signup__header__title {
    color: #FFF;
  }

  .ws-lite-signup__header__subtitle {
    color: rgba(255, 255, 255, 0.7);
  }

  .ws-lite-signup__header__button {
    background: #009EFF !important;
  }

  .ws-lite-signup__item__title {
    color: #FFF;
  }

  .ws-lite-signup__item__subtitle {
    color: rgba(255, 255, 255, 0.7);
  }

  .ws-lite-signup__footer {
    color: rgba(255, 255, 255, 0.7);
  }
}

@media (max-width: 1399px) {
  .ws-lite-signup__header__right {
    position: relative;
  }
}

@media (max-width: 991px) {
  .ws-lite-signup {
    height: auto;
    min-height: auto;
  }

  .ws-lite-signup__header {
    flex-direction: column;
  }

  .ws-lite-signup__header__left {
    order: 2;
    width: 100%;
    flex: initial;
  }

  .ws-lite-signup__header__subtitle {
    width: 100%;
  }

  .ws-lite-signup__header__right {
    order: 1;
    width: 100%;
    right: -75px;
  }

  .ws-lite-signup__content {
    flex-direction: column;
    gap: 16px;
    margin-top: 40px;
  }

  .ws-lite-signup__footer {
    position: relative;
  }
}

@media (max-width: 768px) {
  .ws-lite-signup {
    padding-left: 20px;
    padding-right: 20px;
  }

  .ws-lite-signup__header__right {
    right: -35px;
  }
}

@media (max-width: 520px) {
  .ws-lite-signup__header__title {
    font-size: 24px;
  }

  .ws-lite-signup__gradient-dot {
    width: 250px;
    height: 150px;
  }
}