.submenu-product-card {
    padding: 16px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.2s;
    background-color: var(--submenu-bg);
    margin-bottom: 8px;
    border-radius: 8px;
    border: 1px solid rgba(242, 242, 245, 0.5);
    display: flex;
    flex-direction: column;
    gap: 9px;

    &:hover {
        background-color: var(--submenu-hover);
        transform: translateY(-2px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .product-logo {
        display: flex;
        justify-content: center;
    }

    .product-image {
        border-radius: 6px;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .product-info {
        .product-name-row {
            display: flex;
            justify-content: flex-start;
            gap: 10px;
            align-items: center;
            margin-bottom: 2px;
        }

        .product-name {
            color: var(--submenu-text);
            font-family: var(--Inter, Inter);
            font-size: 16px;
            font-style: normal;
            font-weight: 800;
            letter-spacing: 0.15px;
        }

        .subscribed-badge {
            font-size: 12px;
            font-weight: 500;
            color: #007bff;
           min-width: 89px;
            padding: 3px;
            text-align: center;
            border-radius: 100px;
            background-color: rgba(0, 123, 255, 0.1);
        }

        .product-description {
            font-size: 13px;
            color: var(--submenu-title);
            line-height: 1.5;
            padding-top: 6px;
        }
    }
}

// Add styles for the active state
.submenu-product-card.active {
  background-color: var(--menu-item-active); // Use the active background variable
  border-color: rgba(0, 123, 255, 0.3); // Example: Slightly stronger border for active state

  // Optionally adjust text colors or other properties if needed
  .product-name {
    // color: white; // Example: Change text color on active background
  }
}

// Dark theme adjustments
body.dark .submenu-product-card {
    border-color: rgba(45, 45, 45, 0.5);
    
    &:hover {
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    }

    .subscribed-badge {
        color: #3f94ff;
        background-color: rgba(63, 148, 255, 0.15);
    }
}