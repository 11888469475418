.platinum-program-sign-up {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  user-select: none;
}

.platinum-program-sign-up-left {
  width: 70%;
  background: #1a1c1d;
}

.platinum-program-sign-up-right {
  width: 30%;
  background: #1a1c1d;
}

.platinum-program-sign-up-header {
  padding: 20px 30px;
  background-size: cover;
  background-position: initial;
}

.platinum-program-sign-up-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 56px;
  line-height: 68px;
  background: linear-gradient(269.49deg, #5eeecd 0%, #007bff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 8px;
}

.platinum-program-sign-up-info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  color: #ffffff;
}

.platinum-program-sign-up-info-1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  background: linear-gradient(269.49deg, #5eeecd 0%, #007bff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.platinum-program-sign-up-button {
  margin-top: 75px !important;
  background: linear-gradient(269.49deg, #5eeecd 0%, #007bff 100%) !important;
  border-radius: 5px !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 30px !important;
  color: #ffffff !important;
  text-transform: inherit !important;
  padding: 16px 75px !important;
  -webkit-box-shadow: 0px 16px 18px 0px rgba(0, 123, 255, 0.4) !important;
  -moz-box-shadow: 0px 16px 18px 0px rgba(0, 123, 255, 0.4) !important;
  box-shadow: 0px 16px 18px 0px rgba(0, 123, 255, 0.4) !important;
}

.platinum-program-sign-up-trading-section {
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: 60px;
}

.platinum-program-sign-up-unlock-text {
  width: calc(100% - 300px);
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  padding-bottom: 32px;
  border-bottom: 1px solid #6e6e70;
}

img.platinum-program-sign-up-trading {
  width: 400px;
  position: absolute;
  top: -100px;
  right: -50px;
}

.platinum-program-sign-up-items {
  padding: 30px 30px 8px 30px;
}

.platinum-program-sign-up-item-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.platinum-program-sign-up-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Inter";
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 19px;
  width: calc(50% - 20px);
  color: #ffffff;
  margin-bottom: 20px;
}

.platinum-program-sign-up-item-icon {
  background: #222228;
  border-radius: 10px;
  width: 29px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

.platinum-program-sign-up-price-row {
  padding: 0 30px;
}

.platinum-program-sign-up-price-row-inner {
  padding: 32px 0;
  border-top: 1px solid #6e6e70;
  border-bottom: 1px solid #6e6e70;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}

.platinum-program-sign-up-price {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #ffffff;
}

.platinum-program-sign-up-year {
  font-family: "Inter";
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #bcbcbc;
  margin-left: 8px;
}

.platinum-program-sign-up-button-small {
  background: linear-gradient(269.49deg, #5eeecd 0%, #007bff 100%) !important;
  border-radius: 5px !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  color: #ffffff !important;
  text-transform: inherit !important;
  padding: 10px 40px !important;
  -webkit-box-shadow: 0px 16px 18px 0px rgba(0, 123, 255, 0.4) !important;
  -moz-box-shadow: 0px 16px 18px 0px rgba(0, 123, 255, 0.4) !important;
  box-shadow: 0px 16px 18px 0px rgba(0, 123, 255, 0.4) !important;
  
  &.processing {
    background-color: #FFA726 !important;
    cursor: wait;
  }
  
  &.success {
    background-color: #66BB6A !important;
  }
  
  &.error {
    background-color: #EF5350 !important;
  }
}

.platinum-program-sign-up-footer {
  padding: 32px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.platinum-program-sign-up-footer-left {
  width: 100%;
}

img.platinum-program-sign-up-footer-img {
  width: 250px;
}

.platinum-program-sign-up-footer-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  margin-bottom: 20px;
}

.platinum-program-sign-up-footer-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  margin-bottom: 20px;
}

.platinum-program-sign-up-button-outline {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 450 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #ffffff !important;
  padding: 10px 40px !important;
  border: 1px solid #007bff !important;
  text-transform: inherit !important;
  background: linear-gradient(269.49deg, #5eeecd 0%, #007bff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.platinum-program-gradient-text {
  background: linear-gradient(269.49deg, #5eeecd 0%, #007bff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.platinum-program-accounts {
  margin-top: 15px;
  padding: 10px;
  border-radius: 5px;
  background-color: #222228;
}

.platinum-program-accounts-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  margin-bottom: 10px;
}

.platinum-program-account-button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 8px 12px !important;
  margin-bottom: 5px !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 450 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #ffffff !important;
  background-color: #2a2a30 !important;
  border-radius: 4px !important;
  transition: all 0.2s ease-in-out;
}

.platinum-program-account-button:hover {
  background-color: #3a3a42 !important;
}

.platinum-program-account-button.selected {
  background: linear-gradient(269.49deg, rgba(94, 238, 205, 0.2) 0%, rgba(0, 123, 255, 0.2) 100%) !important;
  border: 1px solid #007bff !important;
}

.mt-3 {
  margin-top: 15px;
}

.platinum-program-plan {
  width: 270px;
  margin-right: 18px;
  background: #000000;
  box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  transition: all 0.3s;

  &:hover {
    transform: translateY(-10px);
  }
}

.platinum-program-plan-header {
  background: #007bff;
  padding: 16px 25px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.platinum-program-plan-content {
  padding: 20px 25px 30px 25px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.platinum-program-plan-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: #ffffff;
  padding-bottom: 12px;
  flex: 1 1;
}

ul.platinum-program-plan-features {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #e4e5e8;
  padding-top: 16px;
  border-top: 1px solid #a6a6a6;
  flex: 1 1 auto;
  max-height: 300px;
  margin-bottom: 40px;
}

.platinum-program-plan-feature span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #e4e5e8;
  margin-bottom: 20px;
  display: flex;
}

.platinum-program-plan-feature span::before {
  font-family: "Inter";
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  content: "• ";
  color: #e4e5e8;
  padding-right: 5px;
  position: relative;
  top: -1px;
}

.platinum-prgoram-plan-price {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #ffffff;
  margin-bottom: 8px;
}

span.platinum-small-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #e4e5e8;
}

.platinum-prgoram-plan-duration {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #e4e5e8;
  margin-bottom: 12px;
}

@media (min-width: 993px) and (max-width: 1200px) {
  .platinum-program-sign-up {
    flex-direction: column;
  }

  .platinum-program-sign-up-left {
    width: 100%;
  }

  .platinum-program-sign-up-right {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .platinum-program-sign-up-title {
    font-size: 47px;
    line-height: 61px;
  }

  .platinum-program-sign-up-info {
    font-size: 34px;
    line-height: 47px;
  }

  .platinum-program-sign-up-info-1 {
    font-size: 34px;
    line-height: 47px;
  }

  .platinum-program-sign-up-button {
    padding: 16px 50px !important;
  }

  .platinum-program-sign-up-unlock-text {
    width: calc(100% - 200px);
  }

  img.platinum-program-sign-up-trading {
    width: 250px;
    position: absolute;
    top: -140px;
    right: 20px;
  }

  .platinum-program-sign-up {
    flex-direction: column;
  }

  .platinum-program-sign-up-left {
    width: 100%;
  }

  .platinum-program-sign-up-right {
    width: 100%;
  }
}

@media (max-width: 767px) {
  img.platinum-program-sign-up-trading {
    display: none;
  }

  .platinum-program-sign-up-unlock-text {
    width: 100%;
  }

  .platinum-program-sign-up-price-row-inner {
    flex-direction: column;
  }

  .platinum-program-plan {
    width: 100%;
    margin-bottom: 40px;
  }

  .platinum-program-plan:last-child {
    margin-bottom: 0px;
  }

  ul.platinum-program-plan-features {
    min-height: 200px;
  }
}

@media (max-width: 600px) {
  .platinum-program-sign-up-button {
    padding: 12px 20px !important;
  }

  .platinum-program-sign-up-unlock-text {
    font-size: 21px;
    line-height: 25px;
    width: 100%;
  }

  .platinum-program-sign-up-footer {
    flex-direction: column;
    align-items: flex-start;
  }

  .platinum-program-sign-up-footer-left {
    width: 100%;
  }

  img.platinum-program-sign-up-footer-img {
    margin: 0 auto;
  }

  img.platinum-program-sign-up-trading {
    display: none;
  }

  .platinum-program-sign-up-item {
    font-size: 14px;
    line-height: 16px;
  }
}

body.light {
  .platinum-program-sign-up-left {
    background: #f8fafc;
  }

  .platinum-program-sign-up-right {
    background: #f8fafc;
  }

  .platinum-program-sign-up-header {
    background-image: none;
  }

  .platinum-program-sign-up-title {
    color: #000;
  }

  .platinum-program-sign-up-info {
    color: #000;
  }

  .platinum-program-sign-up-unlock-text {
    color: #000;
  }

  .platinum-program-sign-up-item {
    color: #000;

    svg {
      color: #ffffff !important;
      fill: #ffffff !important;
    }
  }

  .platinum-program-sign-up-price {
    color: #000;
  }

  .platinum-program-sign-up-year {
    color: #000;
  }

  .platinum-program-sign-up-footer-title {
    color: #000;
  }

  .platinum-program-sign-up-footer-description {
    color: #000;
  }

  .platinum-program-sign-up-button-outline {
    color: #000 !important;
  }

  .platinum-program-plan {
    background: #ffffff;
  }

  .platinum-program-plan-content {
    background: #ffffff;
  }

  .platinum-program-plan-title {
    color: #000;
  }

  .platinum-program-plan-feature span {
    color: #7a7a7a;
  }

  .platinum-program-plan-feature span::before {
    color: #7a7a7a;
  }

  .platinum-prgoram-plan-price {
    color: #000000;
  }

  span.platinum-small-text {
    color: #7a7a7a;
  }

  .platinum-prgoram-plan-duration {
    color: #7a7a7a;
  }
}
.platinum-program-sign-up-result {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
  min-height: calc(100vh - 100px);
}

.platinum-program-sign-up-result-left-part {
  background-size: cover;
  background-position: center;
  width: 70%;
  min-height: calc(100vh - 90px);
  padding: 80px;
}

.platinum-program-sign-up-result-right-part {
  width: 30%;
  padding: 30px 20px;
  background: #191b1c;
}

.platinum-program-big-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: #ffffff;
  margin-bottom: 20px;
}

.platinum-program-small-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #e4e5e8;
  margin-bottom: 40px;
}

.platinum-program-color-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #5eeecd;
  margin-bottom: 10px;
}

.platinum-program-ul {
  list-style-type: none;
}

.platinum-program-li span {
  display: flex;
  align-items: flex-start;
  padding-left: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 200%;
  color: #e4e5e8;
}

.platinum-program-li span::before {
  font-family: "Inter";
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  content: "• ";
  color: #e4e5e8;
  padding-right: 5px;
  position: relative;
  top: -1px;
}

.platinum-program-sign-up-result-icon-wrapper {
  text-align: center;

  svg {
    color: #007bff !important;
    fill: #007bff !important;
    width: 200px !important;
    height: 200px !important;
  }
}

.platinum-program-sign-up-result-loading-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  margin-top: 50px;
}

.platinum-program-sign-up-result-success-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #ffffff;
  margin-top: 25px;
}

.platinum-program-sign-up-result-success-info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  margin-top: 30px;
}

.back-to-dashboard {
  border-radius: 5px !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 450 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #ffffff !important;
  border: 1px solid #007bff !important;
  margin: 0 auto !important;
  display: block !important;
  margin-top: 30px !important;
  text-transform: initial !important;
  padding: 8px 16px !important;
  background: linear-gradient(269.49deg, #5eeecd 0%, #007bff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.platinum-program-sign-up-center-element {
  position: absolute;
  left: 50%;
  top: calc(50% - 90px);
  transform: translate(-50%, -50%);
}

body.light {
  .platinum-program-sign-up-result-left-part {
    background-image: url(../../../assets/images/diamond-bg.png);
  }

  .platinum-program-sign-up-result-right-part {
    background: #ffffff;
  }

  .platinum-program-big-title {
    color: #000;
  }

  .platinum-program-small-title {
    color: #7a7a7a;
  }

  .platinum-program-color-title {
    color: #007bff;
  }

  .platinum-program-li span,
  .platinum-program-li span::before {
    color: #7a7a7a;
  }

  .platinum-program-sign-up-result-loading-text {
    color: #000;
  }

  .platinum-program-sign-up-result-success-title {
    color: #000;
  }

  .platinum-program-sign-up-result-success-info {
    color: #000;
  }
}

@media (max-width: 1200px) {
  .platinum-program-sign-up-result {
    flex-direction: column;
  }

  .platinum-program-sign-up-result-left-part {
    width: 100%;
    background-size: auto;
    min-height: auto;
  }

  .platinum-program-sign-up-result-right-part {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .platinum-program-sign-up-result {
    padding-left: 40px;
    padding-right: 40px;
  }

  .platinum-program-sign-up-result-success-title {
    font-size: 54px;
    line-height: 71px;
  }

  .platinum-program-sign-up-result-success-info {
    font-size: 16px;
    line-height: 21px;
  }
}
