.theme-toggle-container {
  display: flex;
  align-items: center;
  border-radius: 9999px;
  background-color: var(--toggle-bg, #fff);
  padding: 4px;
  width: 100%;
  border: 1px solid var(--toggle-border, #f1f1f4);
  position: relative;
  transition: background-color 0.3s ease, border-color 0.3s ease;

  &::before {
    content: '';
    position: absolute;
    width: calc(50% - 4px);
    height: calc(100% - 8px);
    border-radius: 9999px;
    background-color: var(--toggle-active-bg, #f1f1f4);
    transition: transform 0.4s cubic-bezier(0.45, 0.05, 0.55, 0.95);
    transform: translateX(var(--translate-x, 4px));
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  .theme-toggle-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    width: 50%;
    height: 32px;
    border: none;
    cursor: pointer;
    position: relative;
    background: transparent;
    transition: color 0.3s ease;
    z-index: 1;

    &.active {
      color: var(--toggle-active-color, #000);
    }

    &.inactive {
      color: var(--toggle-inactive-color, #6b7280);

      &:hover {
        color: var(--toggle-hover-color, #374151);
      }
    }

    &:focus {
      outline: none;
    }

    svg {
      transition: transform 0.2s ease;
    }

    &:hover svg {
      transform: scale(1.1);
    }
  }

  &[data-theme="dark"] {
    --translate-x: calc(100% - 4px);
  }
}

.theme-toggle-collapsed {
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--menu-icon);
  padding: 8px;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s;
  
  &:hover {
    background-color: var(--menu-item-hover);
    color: var(--menu-text);
  }
  
  &:focus {
    outline: none;
  }
}

:root {
  // Sidebar theme variables (existing)
  --sidebar-bg: #fff;
  --sidebar-border: #e0e0e0;
  --menu-item-hover: #f5f5f7;
  --menu-item-active: #f0f0f5;
  --menu-text: #000;
  --menu-icon: rgba(0, 0, 0, 0.56);
  --divider-color: rgba(0, 0, 0, 0.12);
  --submenu-bg: white;
  --submenu-title: rgba(0, 0, 0, 0.60);
  --submenu-text: #333;
  --submenu-hover: #f5f5f7;
  
  // Theme toggle specific variables
  --toggle-bg: #fff;
  --toggle-border: #e0e0e0;
  --toggle-active-bg: #f1f1f4;
  --toggle-active-color: #000;
  --toggle-inactive-color: #6b7280;
  --toggle-hover-color: #374151;
}

body.dark {
  // Sidebar theme variables (existing)
  --sidebar-bg: #1d1d1d;
  --sidebar-border: #2d2d2d;
  --menu-item-hover: #2b2b2b;
  --menu-item-active: #0f2740;
  --menu-text: #fff;
  --menu-icon: rgba(255, 255, 255, 0.8);
  --divider-color: #2d2d2d;
  --submenu-bg: #1d1d1d;
  --submenu-title: rgba(255, 255, 255, 0.6);
  --submenu-text: #fff;
  --submenu-hover: #2b2b2b;
  
  // Theme toggle specific variables
  --toggle-bg: #1d1d1d;
  --toggle-border: #2d2d2d;
  --toggle-active-bg: #0f2740;
  --toggle-active-color: #fff;
  --toggle-inactive-color: rgba(255, 255, 255, 0.6);
  --toggle-hover-color: #fff;
  .submenu-title{
    color: var(--submenu-title);
  }
} 