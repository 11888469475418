.arrange-icon {
  z-index: 100000000000000;
  position: absolute;
  right: 10px;
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.remove-icon {
  z-index: 100000000000000;
  float: left;
  position: absolute;
  left: 10px;
  cursor: pointer;
}

.react-resizable-handle-se {
  position: absolute;
  bottom: -15px;
  right: 0;
  cursor: se-resize;
}

.react-resizable-handle-sw {
  position: absolute;
  bottom: 0;
  transform: rotate(90deg);
  left: -15px;
  cursor: sw-resize;
}

.react-resizable-handle-ne {
  position: absolute;
  top: 0;
  transform: rotate(-90deg);
  right: -15px;
  cursor: ne-resize;
}

.react-resizable-handle-nw {
  position: absolute;
  top: -15px;
  transform: rotate(180deg);
  left: 0;
  cursor: ne-resize;
}

.grid-header {
  padding-left: 11px;

  .grid-title {
    color: #000;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .grid-desc {
    color: #a6a6a6;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 4px;
    max-width: 491px;
  }

  .search-wrapper {
    padding-top: 10px;
    display: flex;
    gap: 10px;

    .add-button {
      border-radius: 6px;
      border: 1px solid #007bff;
      background: #007bff;
      margin-top: 7px;
      height: 28.392px;
      padding: 6px 20px;
      align-items: center;
      gap: 6px;
      text-transform: none;
    }
  }

  .bottom-wrapper {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .trash {
      width: 20px;
      height: 20px;
    }

    .rm-chart {
      border-radius: 6px;
      border: 1px solid #a6a6a6;
      display: flex;
      height: 28.392px;
      padding: 8px 20px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      text-transform: none;
      color: #a6a6a6;
      align-self: flex-end;
    }

    .done-chart {
      color: #a6a6a6;
      text-transform: none;
      height: 28.392px;
      border-radius: 6px;
      border: 1px solid #a6a6a6;
    }

    .ticker-count {
      align-self: flex-end;
      color: #000;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

.drag-wrapper {
  border: 1px solid #d9d9d9;
  background: #f1f5fe;
  height: 34px;
  padding: 5px 10px;
  position: relative;
}

.TVChartContainer {
  height: 750px;
  min-height: 620px;
  max-height: none !important;
}

body.mobile-chart {
  .TVChartContainer {
    height: 100vh;
    min-height: 100px;
  }
}

body.mobile {
  .mobile-vimeo-player {
    padding: 10px;
    height: 100vh;
  }

  .market-analysis-event-chat {
    width: 100%;
  }

  .radar {
    max-width: 100% !important;

    .dxfeed-radar {
      height: 100vh !important;
    }
  }
}

.TVChartContainer.example {
  height: 620px;
  min-height: 620px;
  max-height: none !important;
}

.TVChartContainerOptions {
  height: calc(72vh - 290px);
  min-height: 480px;
}

body.mobile {
  .mobile-vimeo-player {
    padding: 10px;
    height: 100vh;
  }

  .market-analysis-event-chat {
    width: 100%;
  }

  .radar {
    max-width: 100% !important;

    .dxfeed-radar {
      height: 100vh !important;
    }
  }
}
/* chart grid css */
/* .TVChartContainer0 {
  height: calc(72vh - 290px);
  min-height: 1480px;
} */
.TVChartContainer0 {
  height: 99%;
  min-height: 99%;
}

.TVChartContainer1 {
  height: 100%;
  min-height: 100%;
}

.TVChartContainer2 {
  height: 100%;
  min-height: 100%;
}

.TVChartContainer3 {
  height: 100%;
  min-height: 100%;
}

.TVChartContainer4 {
  height: 100%;
  min-height: 100%;
}

.TVChartContainer5 {
  height: 100%;
  min-height: 100%;
}

/* new grid css*/
.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  margin-top: 34px !important;
  padding-bottom: 35px;
  background-color: #e7edf785;
  /* padding: 20px; */
}

.react-grid-item span {
  display: inline-block;
}

.react-grid-item.cssTransforms {
  transition-property: transform;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 30px;
  height: 30px;
  /* bottom: -3px;
  right: -4px;
  cursor: se-resize; */
  z-index: 100000000000000;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  border-right: 3px solid black;
  border-bottom: 3px solid black;
}

.grid-item {
  background: #212124;
  border: 1px solid #141414;
  display: flex;
  flex-direction: column;
}

.grid-item__title {
  font-size: 14px;
  color: #fff;
  text-align: center;
}

body.dark {
  .grid-title {
    color: #fff;
  }

  .ticker-count {
    color: #fff !important;
  }

  .drag-wrapper {
    border: 1px solid #141414;
    background: #212124;
  }

  .react-grid-item > .react-resizable-handle::after {
    content: "";
    position: absolute;
    /* right: 3px;
    bottom: 3px; */
    width: 15px;
    height: 15px;
    border-right: 3px solid white;
    border-bottom: 3px solid white;
  }

  .remove-icon {
    color: #a6a6a6;
  }
}
